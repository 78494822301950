<template>
  <div v-loading="isLoading" class="vasp">
    <div class="header">
      <!-- <el-button
        @click="setHandle"
      ><svg-icon icon-class="set" />一键设置</el-button> -->
      <el-button
        @click="runHandle"
      ><svg-icon icon-class="run" />运行</el-button>
      <div class="content">
        <application-public :iscpu="1" :dirs="choiceDirData" :valid="isValid" @validChange="validChange" @changDirs="changDirs" @getData="getData" @formChange="formChange" />
        <div class="right">
          <div class="desc">
            <div class="title">应用介绍</div>
            <div class="text">VASP is a complex package for performing ab-initio quantum-mechanical molecular dynamics (MD) simulations using pseudopotentials or the projector-augmented wave method and a plane wave basis set. The approach implemented in VASP is based on the (finite-temperature) local-density approximation with the free energy as variational quantity and an exact evaluation of the instantaneous electronic ground state at each MD time step. VASP uses efficient matrix diagonalisation schemes and an efficient Pulay/Broyden charge density mixing.</div>
            <div>Tips:VASP 默认并行参数（KPAR=1 & NCORE=1）非常低效，最优的运行参数可大大提高并行扩展性与运行速度,不同总并行核心数，与不同的算例下，NCORE = 8 下的运行时间经常处于最优值，且基本处于 [4,16] 空间范围内。</div>
          </div>
          <div class="app-info">
            <div class="title">应用参数</div>
            <div>
              <!-- <el-form ref="appForm" :rules="appRules" :model="appForm" size="small">
                <el-form-item prop="version" label="版本" label-width="6em">
                  <el-select v-model="appForm.version" placeholder="请选择版本">
                    <el-option label="6.3" value="6.3" />
                    <el-option label="5.4.4" value="5.4.4" />
                  </el-select>
                </el-form-item>
                <el-form-item prop="command" label="命令" label-width="6em">
                  <el-select v-model="appForm.command" placeholder="请选择命令">
                    <el-option label="vasp_std" value="vasp_std" />
                    <el-option label="vasp_std_vtst" value="vasp_std_vtst" />
                    <el-option label="vasp_gam" value="vasp_gam" />
                    <el-option label="vasp_gam_vtst" value="vasp_gam_vtst" />
                    <el-option label="vasp_ncl" value="vasp_ncl" />
                    <el-option label="vasp_ncl_vtst" value="vasp_ncl_vtst" />
                  </el-select>
                </el-form-item>
              </el-form> -->
              <v-form-render ref="VFormVasp" :form-json="formJson" :form-data="formData" :option-data="optionData" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 选择数据目录 -->
    <choice-file :visible="dirFormVisible" :type="choiceType" @confirmDirs="confirmDirs" />
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import store from '@/store'
import {
  addJobs
} from '@/api/file'
import { queryCustomParam } from '@/api/ehpc'
import choiceFile from '../components/ChoiceFile.vue'
import applicationPublic from '../components/ApplicationPublic.vue'

export default {
  components: { choiceFile, applicationPublic },
  data() {
    const validateName = (rule, value, callback) => {
      const regExp = /^[a-z\d][a-z\d\-_]*[a-z\d]$/
      if (value === '') {
        callback(new Error('请输入作业名称'))
      } else {
        if (value.length < 4 || value.length > 15) {
          callback(new Error('作业名称应为4-15个字符'))
        } else {
          if (regExp.test(value) === false) {
            callback(new Error('作业名称仅可包含小写字母数字破折号(-)和下划线(_)且只能以字母数字作为开头结尾'))
          } else {
            callback()
          }
        }
      }
    }
    return {
      valid: 0,
      isValid: 0,
      choiceDirData: '',
      isLoading: true,
      currentRow: -1,
      loading: false,
      loadingDialog: false,
      dirFormVisible: false,
      choiceType: 'dir',
      filesData: [],
      tableData: [],
      data: [],
      path: '',
      queue: '',
      row: '',
      defaultProps: {
        children: 'childDir',
        label: 'name'
      },
      addForm: {
        jobName: '',
        dir: '',
        cpu: 1,
        gpu: 0
      },
      appForm: {
        version: '',
        command: ''
      },
      appRules: {
        version: [{ required: true, trigger: 'change', message: '请选择版本' }],
        command: [{ required: true, trigger: 'change', message: '请选择命令' }]
      },
      rules: {
        jobName: [{ required: true, trigger: 'blur', validator: validateName }],
        dir: [{ required: true, trigger: 'change', message: '请选择目录' }],
        cpu: [{ required: true, trigger: 'change', message: '请选择CPU' }],
        gpu: [{ required: true, trigger: 'change', message: '请选择GPU' }]
      },
      version: {},
      command: {},
      formJson: {},
      formData: {},
      optionData: {},
      jsonData: {},
      jsonNameList: [],
      isHasVersion: false,
      nodes: []
    }
  },
  mounted() {
    this.getCustom()
  },
  methods: {
    // 左侧子组件传值
    getData(isLoading, queue) {
      // this.isLoading = isLoading
      this.queue = queue
    },
    formChange(form) {
      this.addForm = form
    },
    validChange(val) {
      this.valid = val
    },
    // 选择目录 改变
    changDirs() {
      this.choiceType = 'dir'
      this.dirFormVisible = true
    },
    // 时间格式
    // 时间格式化
    formatTime(row, column) {
      const data = row[column.property]
      const dtime = new Date(data)
      const year = dtime.getFullYear()
      let month = dtime.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      let day = dtime.getDate()
      if (day < 10) {
        day = '0' + day
      }
      let hour = dtime.getHours()
      if (hour < 10) {
        hour = '0' + hour
      }
      let minute = dtime.getMinutes()
      if (minute < 10) {
        minute = '0' + minute
      }
      let second = dtime.getSeconds()
      if (second < 10) {
        second = '0' + second
      }
      return (
        year +
        '-' +
        month +
        '-' +
        day +
        ' ' +
        hour +
        ':' +
        minute +
        ':' +
        second
      )
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex
    },
    mousePass(row, column, cell, event) {
      this.currentRow = row.index
    },
    clickDir() {
      this.dirFormVisible = false
    },
    // 生成五位随机数
    randomString(e) {
      var t = 'abcdefghijklmnopqrstuvwxyz0123456789'
      var a = t.length
      var n = ''
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
      return n
    },
    runHandle() {
      this.nodes = []
      this.nodes.push(this.addForm.nodes)
      this.nodes.push(this.addForm.nodes)
      // const script = 'mpirun -np $SLURM_NPROCS -host $hosts ' + this.command.getValue()
      var script = ''
      this.jsonNameList.map(item => {
        script += item + this.$refs.VFormVasp.getWidgetRef(item).getValue()
      })
      this.isValid = 1
      var data
      if (this.isHasVersion) {
        data = {
          'app': 'vasp',
          'coupon_id': '',
          'script': script,
          'tasks': this.addForm.tasks_per_node * this.addForm.nodes,
          'tasks_per_node': this.addForm.tasks_per_node,
          'nodes': this.nodes,
          'partition': this.queue.queueName,
          'gpus': this.addForm.gpu,
          'jobType': 3,
          'name': this.addForm.jobName + this.randomString(5),
          'version': this.$refs.VFormVasp.getWidgetRef('version').getValue(),
          'current_working_directory': this.addForm.dir,
          'time_limit': this.addForm.lateTime === null ? null : parseInt(this.addForm.lateTime)
        }
      } else {
        data = {
          'app': 'vasp',
          'coupon_id': '',
          'script': script,
          'tasks': this.addForm.tasks_per_node * this.addForm.nodes,
          'tasks_per_node': this.addForm.tasks_per_node,
          'nodes': this.nodes,
          'partition': this.queue.queueName,
          'gpus': this.addForm.gpu,
          'jobType': 3,
          'name': this.addForm.jobName + this.randomString(5),
          'version': '6.3',
          'current_working_directory': this.addForm.dir,
          'time_limit': this.addForm.lateTime === null ? null : parseInt(this.addForm.lateTime)
        }
      }
      if (this.addForm.jobName !== '' && this.addForm.dir !== '' && this.valid === 1) {
        this.$refs.VFormVasp.getFormData().then(formData => {
          if (this.queue !== '') {
            addJobs(data).then((response) => {
              if (response.meta.status === 200) {
                ElMessage.success('运行成功')
                const idObj = {
                  id: 3350359504604818,
                  linkId: 1809102210589843
                }
                this.$store.dispatch('core/close', idObj)
                this.$store.dispatch('core/openApp', 3145878294710521)
                this.row = ''
                this.queue = ''
                this.isValid = 3
              } else if (response.meta.status === 5005) {
                ElMessage.error('余额不足！')
              } else {
                ElMessage.error(response.meta.msg)
              }
            })
          } else {
            ElMessage.error('未选择队列信息，请点击选中队列信息！')
          }
        })
      }
    },
    setHandle() {
      this.appForm.start = 'vasp_std'
    },
    // 获取自定义参数
    getCustom() {
      queryCustomParam(1841485563859686).then((response) => {
        // 对自定义参数进行解密处理
        const Base64 = require('js-base64').Base64
        const Json = Base64.decode(response.data)
        this.jsonData = JSON.parse(Json)
        if (response.meta.status === 200) {
          this.$refs.VFormVasp.setFormJson(Json)
          this.$nextTick(() => {
            // this.version = this.$refs.VFormVasp.getWidgetRef('version')
            // this.command = this.$refs.VFormVasp.getWidgetRef('command')
            this.jsonData.widgetList.forEach(e => {
              if (e.options.name !== 'version' && e.options.name !== 'focus') {
                this.jsonNameList.push(e.options.name)
              }
            })
            this.isHasVersion = this.jsonData.widgetList.some(item => item.options.name === 'version')
            this.isLoading = false
          })
        }
      })
    },
    // 选择文件
    changFiles() {
      this.choiceType = 'file'
      this.dirFormVisible = true
    },
    // 确定选择目录
    confirmDirs(data, dirFormVisible, type) {
      this.dirFormVisible = dirFormVisible
      if (type === 'dir') {
        this.addForm.dir = data
        this.choiceDirData = data
      }
    }
  }
}
</script>

<style lang="less" scoped>
.vasp {
  padding: 20px;
  height: 100%;
  width: 100%;
  overflow: auto;
  ::v-deep .el-dialog{
    max-height: 600px;
    .el-dialog__body{
      max-height: 600px;
    }
  }
  .header {
    text-align: right;
    margin-bottom: 15px;
    ::v-deep .el-button {
      margin-right: 10px;
      background-color: #437aec;
      color: #fff;
      padding: 10px 16px;
      border-radius: 2px;
      min-height: 34px;
    }
    .svg-icon {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-right: 6px;
    }
  }
  .content {
    text-align: left;
    display: flex;
    width: 100%;
    height: 100%;
    .left {
      margin-top: 20px;
      padding: 30px 0;
      background: rgba(245, 247, 250, 0.5);
      border: 1px solid #E8E8E8;
      min-width: 320px;
      width: 45%;
      position: relative;
      height: 100%;
      .el-form-item--small.el-form-item{
        margin-bottom: 40px;
      }
      .box{
        padding: 0 20px;
      }
      .middle{
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .border-style{
          border-bottom: 1px solid #D8D8D8;
          width: 100px;
        }
      }
      .title {
      position: absolute;
      top: -15px;
      left: 20px;
      width: 80px;
height: 30px;
line-height: 30px;
text-align: center;
background: #FFFFFF;
border: 1px solid #E8E8E8;
    }
      ::v-deep th.el-table__cell{
		background-color: #F5F5F5;
	}
    }
    .right {
      margin-top: 20px;
      padding: 0 10px;
      width: 100%;
      height: 100%;
      margin-left: 10px;
      .text{
        text-indent: 2em;
      }
       .desc{
         margin-bottom: 30px;
       }
      .desc,
      .app-info{
        position: relative;
        border: 1px solid #E8E8E8;
        padding: 30px 20px;
        background: rgba(245, 247, 250, 0.5);
      }
      .title{
        position: absolute;
      top: -15px;
      left: 20px;
      width: 80px;
height: 30px;
line-height: 30px;
text-align: center;
background: #FFFFFF;
border: 1px solid #E8E8E8;
      }
    }
  }
  ::v-deep .el-dialog{
  border-radius: 20px;
}
::v-deep .el-dialog__header{
      border-bottom: 1px solid #d8d8d8;
      background: #f5f5f5;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    ::v-deep .el-dialog__body{
      padding: 0;
    }
    ::v-deep .el-icon{
      color: #333;
    }
}
</style>
<style>
/* .el-dialog{
  border-radius: 20px;
}
.el-dialog__header{
      border-bottom: 1px solid #d8d8d8;
      background: #f5f5f5;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    .el-dialog__body{
      padding: 0;
    } */
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 12px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 6px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #d8d8d8;
}
</style>
